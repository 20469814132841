@import './Styles/_variables.scss';

body {
  margin: 0px;
  padding: none;
  background-color: #010055;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 1000'%3E%3Cg %3E%3Ccircle fill='%23010055' cx='50' cy='0' r='50'/%3E%3Cg fill='%2318015b' %3E%3Ccircle cx='0' cy='50' r='50'/%3E%3Ccircle cx='100' cy='50' r='50'/%3E%3C/g%3E%3Ccircle fill='%23270160' cx='50' cy='100' r='50'/%3E%3Cg fill='%23350165' %3E%3Ccircle cx='0' cy='150' r='50'/%3E%3Ccircle cx='100' cy='150' r='50'/%3E%3C/g%3E%3Ccircle fill='%2342016a' cx='50' cy='200' r='50'/%3E%3Cg fill='%234e016f' %3E%3Ccircle cx='0' cy='250' r='50'/%3E%3Ccircle cx='100' cy='250' r='50'/%3E%3C/g%3E%3Ccircle fill='%235a0274' cx='50' cy='300' r='50'/%3E%3Cg fill='%23660478' %3E%3Ccircle cx='0' cy='350' r='50'/%3E%3Ccircle cx='100' cy='350' r='50'/%3E%3C/g%3E%3Ccircle fill='%2372057d' cx='50' cy='400' r='50'/%3E%3Cg fill='%237d0881' %3E%3Ccircle cx='0' cy='450' r='50'/%3E%3Ccircle cx='100' cy='450' r='50'/%3E%3C/g%3E%3Ccircle fill='%23890b85' cx='50' cy='500' r='50'/%3E%3Cg fill='%23950f89' %3E%3Ccircle cx='0' cy='550' r='50'/%3E%3Ccircle cx='100' cy='550' r='50'/%3E%3C/g%3E%3Ccircle fill='%23a1138c' cx='50' cy='600' r='50'/%3E%3Cg fill='%23ad178f' %3E%3Ccircle cx='0' cy='650' r='50'/%3E%3Ccircle cx='100' cy='650' r='50'/%3E%3C/g%3E%3Ccircle fill='%23b91b93' cx='50' cy='700' r='50'/%3E%3Cg fill='%23c42096' %3E%3Ccircle cx='0' cy='750' r='50'/%3E%3Ccircle cx='100' cy='750' r='50'/%3E%3C/g%3E%3Ccircle fill='%23d02498' cx='50' cy='800' r='50'/%3E%3Cg fill='%23dc299b' %3E%3Ccircle cx='0' cy='850' r='50'/%3E%3Ccircle cx='100' cy='850' r='50'/%3E%3C/g%3E%3Ccircle fill='%23e82f9d' cx='50' cy='900' r='50'/%3E%3Cg fill='%23f3349f' %3E%3Ccircle cx='0' cy='950' r='50'/%3E%3Ccircle cx='100' cy='950' r='50'/%3E%3C/g%3E%3Ccircle fill='%23ff3aa1' cx='50' cy='1000' r='50'/%3E%3C/g%3E%3C/svg%3E");
  background-attachment: fixed;
  background-size: contain;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  margin: 0px;
  padding: 0px;
}

.App-link {
  color: #61dafb;
}

// @keyframes App-logo-spin {
//   from {
//     transform: rotate(0deg);
//   }
//   to {
//     transform: rotate(360deg);
//   }
// }

@media only screen and (max-width: 740px) {
  .App {
    height: 100vh;
  }
  body {
    height: 100vh;
  }
}