@import '../Styles/_variables.scss';

.header-bar {
    background-color: $lavender-floral;
    margin-top: 0px;
    margin-bottom: 0px;
    margin: 0px;
    padding: none;
    text-align: center;
}

.home-button {
    display: inline-block;
    margin-top:10px;
}
.home-button:hover {
    background: radial-gradient($soap, $lavender-floral);
    background: radial-gradient(closest-side, $soap, $soap, $lavender-floral);
}
.nav-items {
    display: inline-block;
    list-style: none;
    margin: 0px;
    padding: 0px;
    margin-top:-10px;
    padding-left: 19px;
}
.nav-link {
    display: inline-block;
    padding-right: 19px;
    
}

.link {
    text-decoration: none;
    font-family: $vt;
    color: $soap;
}

.link:hover {
    color: $razzmatazz;
}
.active-link {
    color: white;
}
