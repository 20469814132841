@import '../Styles/variables.scss';

.Page-Container {
    margin: 0px;
    padding: none;
    text-align: center;
    width: 100%;
    height: 100%
}
.Page {
    width: 619px;
    margin: 0 auto;
    padding-top: 19px;
    padding-left: 19px;
    text-align: left;

}
.Page-Title {
    font-family: $share-tech;
    font-size: 40px;
    color: $razzmatazz;
    margin: 0px;
}

.Page-Para {
    font-family: $cutive;
    color: $lavender-blush;
    margin: 19px 19px 19px 19px;
}

.Para-Indent {
    margin-top: 2vw;
    margin-left: 2.5vw;
    margin-right: 2vw;
    font-style:italic;
    margin-bottom: 2vw;
}

.tab-list {
    padding-left: 0;
    display: inline-block;
    position: absolute;
    margin-left:-130px;
  }
  
  .tab-list-item {
    text-align: right;
    font-family: $vt;
    font-size: 19px;
    color: $soap;
    display: block;
    list-style: none;
    padding: 0.5rem 0.75rem;
    cursor:crosshair;
  }
  
  .tab-list-active {
      color: $electric-blue;
  }

  .tab-content {
      display: inline-block;
      width: 619px;
      margin-top: 19px;
      overflow-y:auto;
  }

  .Paper-Container {
      background: white;
      margin: 3vw 0vw 3vw 3vw;
      padding: 5px 15px;
  }
  .Papers-Menu {
    padding-top: 2vh;
    margin-left: 2vw;
    
    .Papers-Menu-Item {
        text-decoration: None;
        font-family: $share-tech;
        color: $lavender-blush
        
        
    }
    .Papers-Menu-Item:hover {
        color: $electric-blue;
    }
  }
  
  .Paper-Title {
      font-family: $share-tech;
      color: $sapphire-blue;
      font-size: 19pt;

  }
  .Paper-Break {
      width: 19vw;
      border-bottom: 0.19px solid $onyx;
  }

  .Paper-Details {
      font-family: $share-tech;
  }

  .Paper-Body {
      font-family: $alice;
      p {
        color: $onyx;
        font-size: 14pt;
      }
      a {
          text-decoration: none;
          color: $azure;
      }
      
  }

  .Paper-Image {
      border:none;
      width: 100%
  }

  .back-button {
      font-family: $vt;
      color: white;
      text-decoration: none;
      margin-left: 1vw;
  }
  .back-button:hover{
      color: $electric-blue
  }

  .Image-Container {
    //   background: black;
    //   width: 100vw;
      margin: 0 auto;
      text-align:center;
      
  }

  @media only screen and (max-width: 740px) {
    .Page {
        width: 80vw;
        margin: 0 auto;
        padding-top: 19px;
        padding-left: 19px;
        text-align: left;
        max-height: 100vh;
    
    }
    .Page-Container {
        margin: 0px;
        padding: none;
        text-align: center;
        width: 100%;
        height: 100vh;
    }
    .Paper-Container {}
    iframe {
        width: 100%
    }
  }