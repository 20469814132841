// Font families

$sail: 'Sail', cursive;
$vt: 'VT323', monospace;
$share-tech: 'Share Tech Mono', monospace;
$cutive: 'Cutive Mono', monospace;
$alice: 'Alice', serif;

// Colour Palette

$razzmatazz: #F42272;
$lavender-floral: #CA85DE;
$lavender-blush: #FAEEF8;
$soap: #E6C6ED;
$electric-blue: #58F8F0;
$azure: #0496FF;
$sapphire-blue: #006BA6;
$onyx: #333745;
 




