@import '../../Styles/_variables.scss';

.main-container {
    padding: 0px 19px 0px 19px;
}
.para {
    font-family: $cutive;
    color: $lavender-blush;
    text-align: left;

}