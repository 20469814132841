body {
  margin: 0px;
  padding: none;
  background-color: #010055;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 1000'%3E%3Cg %3E%3Ccircle fill='%23010055' cx='50' cy='0' r='50'/%3E%3Cg fill='%2318015b' %3E%3Ccircle cx='0' cy='50' r='50'/%3E%3Ccircle cx='100' cy='50' r='50'/%3E%3C/g%3E%3Ccircle fill='%23270160' cx='50' cy='100' r='50'/%3E%3Cg fill='%23350165' %3E%3Ccircle cx='0' cy='150' r='50'/%3E%3Ccircle cx='100' cy='150' r='50'/%3E%3C/g%3E%3Ccircle fill='%2342016a' cx='50' cy='200' r='50'/%3E%3Cg fill='%234e016f' %3E%3Ccircle cx='0' cy='250' r='50'/%3E%3Ccircle cx='100' cy='250' r='50'/%3E%3C/g%3E%3Ccircle fill='%235a0274' cx='50' cy='300' r='50'/%3E%3Cg fill='%23660478' %3E%3Ccircle cx='0' cy='350' r='50'/%3E%3Ccircle cx='100' cy='350' r='50'/%3E%3C/g%3E%3Ccircle fill='%2372057d' cx='50' cy='400' r='50'/%3E%3Cg fill='%237d0881' %3E%3Ccircle cx='0' cy='450' r='50'/%3E%3Ccircle cx='100' cy='450' r='50'/%3E%3C/g%3E%3Ccircle fill='%23890b85' cx='50' cy='500' r='50'/%3E%3Cg fill='%23950f89' %3E%3Ccircle cx='0' cy='550' r='50'/%3E%3Ccircle cx='100' cy='550' r='50'/%3E%3C/g%3E%3Ccircle fill='%23a1138c' cx='50' cy='600' r='50'/%3E%3Cg fill='%23ad178f' %3E%3Ccircle cx='0' cy='650' r='50'/%3E%3Ccircle cx='100' cy='650' r='50'/%3E%3C/g%3E%3Ccircle fill='%23b91b93' cx='50' cy='700' r='50'/%3E%3Cg fill='%23c42096' %3E%3Ccircle cx='0' cy='750' r='50'/%3E%3Ccircle cx='100' cy='750' r='50'/%3E%3C/g%3E%3Ccircle fill='%23d02498' cx='50' cy='800' r='50'/%3E%3Cg fill='%23dc299b' %3E%3Ccircle cx='0' cy='850' r='50'/%3E%3Ccircle cx='100' cy='850' r='50'/%3E%3C/g%3E%3Ccircle fill='%23e82f9d' cx='50' cy='900' r='50'/%3E%3Cg fill='%23f3349f' %3E%3Ccircle cx='0' cy='950' r='50'/%3E%3Ccircle cx='100' cy='950' r='50'/%3E%3C/g%3E%3Ccircle fill='%23ff3aa1' cx='50' cy='1000' r='50'/%3E%3C/g%3E%3C/svg%3E");
  background-attachment: fixed;
  background-size: contain; }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace; }

.App {
  margin: 0px;
  padding: 0px; }

.App-link {
  color: #61dafb; }

@media only screen and (max-width: 740px) {
  .App {
    height: 100vh; }
  body {
    height: 100vh; } }

.header-bar {
  background-color: #CA85DE;
  margin-top: 0px;
  margin-bottom: 0px;
  margin: 0px;
  padding: none;
  text-align: center; }

.home-button {
  display: inline-block;
  margin-top: 10px; }

.home-button:hover {
  background: -webkit-radial-gradient(#E6C6ED, #CA85DE);
  background: radial-gradient(#E6C6ED, #CA85DE);
  background: -webkit-radial-gradient(closest-side, #E6C6ED, #E6C6ED, #CA85DE);
  background: radial-gradient(closest-side, #E6C6ED, #E6C6ED, #CA85DE); }

.nav-items {
  display: inline-block;
  list-style: none;
  margin: 0px;
  padding: 0px;
  margin-top: -10px;
  padding-left: 19px; }

.nav-link {
  display: inline-block;
  padding-right: 19px; }

.link {
  text-decoration: none;
  font-family: "VT323", monospace;
  color: #E6C6ED; }

.link:hover {
  color: #F42272; }

.active-link {
  color: white; }

.Page-Container {
  margin: 0px;
  padding: none;
  text-align: center;
  width: 100%;
  height: 100%; }

.Page {
  width: 619px;
  margin: 0 auto;
  padding-top: 19px;
  padding-left: 19px;
  text-align: left; }

.Page-Title {
  font-family: "Share Tech Mono", monospace;
  font-size: 40px;
  color: #F42272;
  margin: 0px; }

.Page-Para {
  font-family: "Cutive Mono", monospace;
  color: #FAEEF8;
  margin: 19px 19px 19px 19px; }

.Para-Indent {
  margin-top: 2vw;
  margin-left: 2.5vw;
  margin-right: 2vw;
  font-style: italic;
  margin-bottom: 2vw; }

.tab-list {
  padding-left: 0;
  display: inline-block;
  position: absolute;
  margin-left: -130px; }

.tab-list-item {
  text-align: right;
  font-family: "VT323", monospace;
  font-size: 19px;
  color: #E6C6ED;
  display: block;
  list-style: none;
  padding: 0.5rem 0.75rem;
  cursor: crosshair; }

.tab-list-active {
  color: #58F8F0; }

.tab-content {
  display: inline-block;
  width: 619px;
  margin-top: 19px;
  overflow-y: auto; }

.Paper-Container {
  background: white;
  margin: 3vw 0vw 3vw 3vw;
  padding: 5px 15px; }

.Papers-Menu {
  padding-top: 2vh;
  margin-left: 2vw; }
  .Papers-Menu .Papers-Menu-Item {
    -webkit-text-decoration: None;
            text-decoration: None;
    font-family: "Share Tech Mono", monospace;
    color: #FAEEF8; }
  .Papers-Menu .Papers-Menu-Item:hover {
    color: #58F8F0; }

.Paper-Title {
  font-family: "Share Tech Mono", monospace;
  color: #006BA6;
  font-size: 19pt; }

.Paper-Break {
  width: 19vw;
  border-bottom: 0.19px solid #333745; }

.Paper-Details {
  font-family: "Share Tech Mono", monospace; }

.Paper-Body {
  font-family: "Alice", serif; }
  .Paper-Body p {
    color: #333745;
    font-size: 14pt; }
  .Paper-Body a {
    text-decoration: none;
    color: #0496FF; }

.Paper-Image {
  border: none;
  width: 100%; }

.back-button {
  font-family: "VT323", monospace;
  color: white;
  text-decoration: none;
  margin-left: 1vw; }

.back-button:hover {
  color: #58F8F0; }

.Image-Container {
  margin: 0 auto;
  text-align: center; }

@media only screen and (max-width: 740px) {
  .Page {
    width: 80vw;
    margin: 0 auto;
    padding-top: 19px;
    padding-left: 19px;
    text-align: left;
    max-height: 100vh; }
  .Page-Container {
    margin: 0px;
    padding: none;
    text-align: center;
    width: 100%;
    height: 100vh; }
  iframe {
    width: 100%; } }

p {
  font-size: 24px;
  color: white; }

.Image-Container {
  margin: 0 auto; }
  .Image-Container .Home-Headshot {
    width: 50%; }

.main-container {
  padding: 0px 19px 0px 19px; }

.para {
  font-family: "Cutive Mono", monospace;
  color: #FAEEF8;
  text-align: left; }

